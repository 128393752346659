body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(#416e8a, #000000);
    font-family: Arial, sans-serif;
    color: white;
}

.contact-container {
    width: 80vw;
    padding: 20px;
    background: radial-gradient(#15232c, #000000);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin-top: 50px;
    text-align: center;
    padding-bottom: 100px;
}

p {
    margin-bottom: 30px;
    margin-top: 30px;
}

.contact-container h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    animation: dropInBounce 1s ease-out;
}

@keyframes dropInBounce {
    0% {
        transform: translateY(-200%);
    }
    60% {
        transform: translateY(30px);
    }
    75% {
        transform: translateY(-20px);
    }
    85% {
        transform: translateY(15px);
    }
    92% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

h2 {
    margin-top: 40px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    border: #072a62 solid 1px;
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.social-links {
    margin-top: 1px;
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
}

.social-icon {
    color: white;
    font-size: 3rem;
    transition: color 0.3s ease;
}

.social-icon:hover {
    color: #072a62;
}