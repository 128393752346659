body {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background:radial-gradient(#416e8a, #000000);
}

.home-container {
    text-align: center;
    height: 100%;
    width: 80vw;
    padding: 20px;
    background:radial-gradient(#15232c, #000000);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: white;
    font-family: sans-serif;
    margin-top: 50px;
    margin-bottom: 50px;
}

.home-container h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    animation: headerAnimation 2s ease-in-out;
}

p {
    font-size: 1.2rem;
    line-height: 1.5;
}

.link-container {
    display: flex;
    padding-top: 20px;
    justify-content: center;
    gap: 20px;
    margin-top: 80px;
}

.link {
    text-align: center;
    text-decoration: none;
    display: flex;
    flex-direction: column;
}

.button {
    background-color: #072a62;
    color: white;
    padding: 10px 20px 10px 20px;
    margin-top: 40px;
    text-decoration: none;
    border-radius: 10px;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #2d49ba;
    color: #000000;
}

@keyframes headerAnimation {
    0% {
        transform: translateX(-100vw);
        opacity: 0;
    }
    25% {
        transform: translateX(0);
        opacity: 1;
    }
    50% {
        transform: translateX(100vw);
        opacity: 0;
    }
    75% {
        transform: translateX(0) translateY(-100vh);
        opacity: 0;
    }
    100% {
        transform: translateX(0) translateY(0);
        opacity: 1;
    }
}

@keyframes gradientPulse {
    0% {
        background-size: 100% 100%;
    }
    50% {
        background-size: 120% 120%;
    }
    100% {
        background-size: 100% 100%;
    }
}


