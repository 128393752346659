.projects-container {
    text-align: center;
    height: 100%;
    width: 80vw;
    padding: 20px;
    background:radial-gradient(#15232c, #000000);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: white;
    font-family: sans-serif;
    margin-top: 50px;
    margin-bottom: 40px;
}

.project-container {
    border: 2px solid #072a62;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    transition: all 0.7s ease;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease, all 0.7s ease;
}

.project-container.fade-in {
    opacity: 1;
    transform: translateY(0);
}

.project-container:hover {
    background:radial-gradient(#15232c, #000000);
    transform: scale(1.1);
    border: #072a62 6px solid;
    margin-bottom: 4rem;
}

.project-container h2 {
    margin: 0 0 10px 0;
}

.project-container p {
    margin: 0 0 20px 0;
}

.image-gallery {
    margin-top: 20px;
    display: flex;
    gap: 20px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.image-gallery::-webkit-scrollbar {
    display: none;
}

.image-gallery img {
    max-height: 500px;
    border-radius: 10px;
    margin-right: 10px;
}

.project-container p{
    font-size: .9rem;
}

.project-container a {
    display: inline-block;
    margin-top: 10px;
    color: #ffffff;
    text-decoration: none;
}

.highlighted-projects, .ML-projects, .web-projects {
    border: 8px solid #072a62;
    border-radius: 10px;
}

.highlighted-projects, .ML-projects{
    margin-bottom: 40px;
}

.technical-info-container {
    margin: 20px 0;
    background: rgba(7, 42, 98, 0.1);
    border-radius: 10px;
    padding: 15px;
    text-align: center;  /* Center the content */
}

.technical-info-container h4 {
    margin-bottom: 10px;

}

.tech-tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 15px;
    justify-content: center;  /* Center the tags horizontally */
}

.tech-tag {
    background: #072a62;
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.9rem;
    transition: all 0.3s ease;
    display: inline-block;  /* Ensures proper spacing */
}

.tech-tag:hover {
    transform: scale(1.05);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.sector-tag { 
    background-color: #1750cc; 
}

.language-tag { 
    background-color: #072a62; 
}
.package-tag { 
    background-color: #072a62; 
}

.skill-tag { 
    background-color: #072a62; 
}

.parallax-header {
    font-size: 4rem;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
    transition: transform 0.3s ease;
}

.section-header {
    font-size: 2.5rem;
    color: #165fd3;
    margin-bottom: 20px;
}

@keyframes dropInBounce {
    0% {
        transform: translateY(-200%);
    }
    60% {
        transform: translateY(30px);
    }
    75% {
        transform: translateY(-20px);
    }
    85% {
        transform: translateY(15px);
    }
    92% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}

.parallax-header {
    animation: dropInBounce 1s ease-in-out 1;
}