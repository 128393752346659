.navbar {
    width: 100%;
    background:#000000;
    overflow: hidden;
}

.navbar a {
    float: left;
    display: block;
    color: white;
    text-align: center;
    padding: 14px 14px;
    text-decoration: none;
    font-size: 17px;
}

.navbar a:hover {
    background-color: #ddd;
    color: black;
}

.navbar .active {
    background-color: #072a62;
    color: white;
}

@media screen and (max-width: 600px) {
    .navbar a {
        float: none;
        width: 100%;
    }
}