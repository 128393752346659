.social-links {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
}

.social-icon, .social-icon2 {
    color: white;
    font-size: 3rem;
    transition: transform 0.3s ease, color 0.3s ease;
    overflow: hidden;
}

.social-icon:hover, .social-icon2:hover {
    color: #072a62;
    transform: scale(1.5);
    animation: none !important;
}

.pulse-icons .social-icon, .pulse-icons .social-icon2 {
    animation: pulse 2.5s infinite;
}

.fly-in-icons .social-icon, .fly-in-icons .social-icon2 {
    animation: flyIn 2s ease-out forwards;
}

.fly-in-pulse-icons .social-icon, .fly-in-pulse-icons .social-icon2 {
    animation: 
        flyIn 2s ease-out forwards,
        pulse 2.5s infinite 2s;
}

@keyframes flyIn {
    0% {
        opacity: 0;
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
        opacity: 1;
    }
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
        color: #072a62;
    }
}

.fly-in-icons .social-icon,
.fly-in-pulse-icons .social-icon { animation-delay: 0s, 2s; }
.fly-in-icons .social-icon2,
.fly-in-pulse-icons .social-icon2 { animation-delay: 0.5s, 2.5s; }